import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
//
import Header from './Header';
export default function CompactLayout({ children }) {
    const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { isOffset: isOffset }), _jsx(Container, { component: "main", children: _jsxs(Stack, { sx: {
                        py: 12,
                        m: 'auto',
                        maxWidth: 400,
                        minHeight: '100vh',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }, children: [children, _jsx(Outlet, {})] }) })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box } from '@mui/material';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// components
import Logo from '../../components/logo';
export default function Header({ isOffset }) {
    const theme = useTheme();
    return (_jsxs(AppBar, { color: "transparent", sx: { boxShadow: 0 }, children: [_jsx(Toolbar, { sx: {
                    justifyContent: 'space-between',
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_MAIN_DESKTOP,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(isOffset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        height: {
                            md: HEADER.H_MAIN_DESKTOP - 16,
                        },
                    }),
                }, children: _jsx(Logo, {}) }), isOffset && _jsx(Shadow, {})] }));
}
// ----------------------------------------------------------------------
function Shadow({ sx, ...other }) {
    return (_jsx(Box, { sx: {
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            m: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            boxShadow: (theme) => theme.customShadows.z8,
            ...sx,
        }, ...other }));
}
